import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=02115290&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=ts&"
export * from "./detail.vue?vue&type=script&lang=ts&"
import style0 from "./detail.vue?vue&type=style&index=0&id=02115290&lang=less&scoped=true&"
import style1 from "./detail.vue?vue&type=style&index=1&id=02115290&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02115290",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/root/workspace/com.jufair.vue_Z6yh/components/SectionTitle/index.vue').default})
