















































































































































































































































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import _ from 'lodash';
import xss from 'xss';
import Vue from 'vue';
import UUID from 'uuid';
import { ElUploadInternalFileDetail } from 'element-ui/types/upload';
import moment from 'moment';
// import TopBg from '../fair/component/detailTopBg.vue';
// import Invoice from './component/invoice.vue';
import { mapState } from 'vuex';

import { ResponseCode } from '~/util/constant';
// import AsideFair from '~/components/AsideFair/index.vue';
import {
  getContent,
  checkEmail,
  message,
  uniqueArray,
  getTodayDate,
  bucketCdnHost,
  bucketHost,
  getXOssProcess,
  handleNewsUrl,
} from '~/util/common';
import SingleUpload from '~/components/SingleUpload/index.vue';
import hesuan from '~/util/hesuan';
import payTicketMixin from '~/pages/pay/pay-ticket-mixin';
import { TraceCode } from '~/util/trace';
import JuMap from '~/components/Map/index.vue';
import NewsItem2 from '~/pages/fair/component/NewsItem2.vue';

// import {
//   bucketCdnHost,
//   bucketHost,
//   // getContent,
//   // MINI_HOST,
//   // sleep,
// } from '~/util/common';

export default Vue.extend({
  // layout: 'layout2',
  components: {
    // AsideFair,
    // Invoice,
    SingleUpload,
    // TopBg,
    JuMap,
    NewsItem2,
  },
  mixins: [payTicketMixin],
  async asyncData(ctx) {
    // const id = _.get(ctx, 'params.id', null);
    const lang = _.get(ctx, 'query.lang', null);
    let {
      id, // 展会id
      a: historyId = 0, // 历史id
      h: historyStatus = 0, // 是否历届展会 1是 0否
      aid: exhibitionAliasId = 0, // 当前百科详情的别名id
    } = ctx.params;

    // const historyId = ctx.query.id;
    // if (historyStatus == 1) {
    //   historyId = exhibitionAliasId;
    // }
    let apiErrorList: any = [];
    // 参数错误 显示404(正确参数: wiki/3357-396-0-0.html, 错误:wiki/3357-0-0-396.html)
    if (historyId == 0) {
      return ctx.error({
        message: 'Page not found',
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';

    const res: any[] = await Promise.all([
      historyStatus == 1
        ? ctx.$axios.post(`/api/wiki/history/detail`, {
            id: historyId,
            exhibitionId: id,
            aliasId: exhibitionAliasId,
          })
        : ctx.$axios.post(`/api/wiki/detail`, {
            id: historyId,
            exhibitionId: id,
          }),
      ctx.$axios.post('/api/wiki/exhibition/hotList', {
        locale: langApi,
        sortHot: 'desc',
        pageNum: 1,
        pageSize: 10,
      }),
      // ctx.$axios.get(`/api/share/queryShareTicket/${id}`),
      ctx.$axios.post(`/api/newsApi/getNewsbyExhibitionId/`, {
        locale: langApi,
        exhibitionId: id,
        pageNum: 1,
        pageSize: 13,
      }), // 资讯列表
    ]);
    const [fairRes, hotFairRes, newsByFairIdRes] = res;
    // console.log('600--', fairRes.data);
    // console.log('601--', fairRes.data.hasOwnProperty('exhibitionAlias'));
    // 展会资讯
    let fairNews: any[] = [];
    if (historyStatus == 0) {
      if (newsByFairIdRes.code === ResponseCode.SUCCESS) {
        fairNews = newsByFairIdRes.data.records.map((n: any) => ({
          newsId: n.id,
          viewUrl: n.viewUrl,
          newsName: n.title,
          views: n.viewCount,
          logo: n.coverImg,
          publishTime: n.publishTime,
          description: n.description,
        }));
      } else {
        apiErrorList.push({
          code: newsByFairIdRes.code,
          message: newsByFairIdRes.message,
        });
      }
    }

    let hotWikiList: any = [];
    if (hotFairRes.code === ResponseCode.SUCCESS) {
      let list = hotFairRes.data.records;

      if (list.length > 0) {
        let idList: any = [];
        list.map((i: any) => idList.push(i.exhibitionId));
        await ctx.$axios
          .post(`/api/wiki/aliasList`, {
            exhibitionIdList: idList,
          })
          .then((aliasRes: any) => {
            if (aliasRes.code === ResponseCode.SUCCESS) {
              let aliasList: any = aliasRes.data;
              let resList: any = [];
              list.map((item: any) => {
                let findRes: any = aliasList.find(
                  (x: any) =>
                    x.exhibitionId == item.exhibitionId && x.isPrimary == 1
                );
                if (!findRes) {
                  findRes = aliasList.find(
                    (x: any) => x.exhibitionId == item.id
                  );
                }
                if (findRes) {
                  resList.push({
                    id: findRes ? findRes.id : '',
                    exhibitionId: item.exhibitionId,
                    exhibitionName: item.exhibitionName,
                    // exhibitionAliasName: findRes
                    //   ? findRes.exhibitionName
                    //   : item.exhibitionName,
                    // cityName: findRes ? findRes.cityName : '',
                    exhibitionAliasName: findRes
                      ? findRes.exhibitionAlias
                      : item.exhibitionName,
                    cityName: item.cityName, // findRes ? findRes.cityName : '',
                    lunchTime: item.lunchTime,
                  });
                }
              });
              hotWikiList = resList;
            }
          });
      }
    } else {
      apiErrorList.push({ code: hotFairRes.code, message: hotFairRes.message });
    }
    let fairImage: any = []; // 现场图片

    // let fair: Record<string, any> = {};
    let fair: any = {};
    const industry: any[] = [];
    let industryId: any[] = [];
    let industryName: any[] = [];
    let historyFairList: any = [];
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data || {};
      if (
        !fair.hasOwnProperty('exhibitionAlias') ||
        (fair.exhibitionAlias ?? '') === ''
      ) {
        fair.exhibitionAlias = '';
      }
      if (fair.photos) {
        fair.photos.slice(0, 6).map((item: { resourceUrl: any }) => {
          fairImage.push(item.resourceUrl);
        });
      }
      if (historyStatus == 1) {
        let list: any = fairRes.data.relateds || []; // relateds 历届数据  type = 1 资讯, 4图片
        let imageList: any = [];
        list.map((item: any) => {
          item['newsId'] = item.relateId;
          item['newsName'] = item.title;
          item['logo'] = item.picture;
          item['description'] = item.profile;
          if (item.type == 4) {
            imageList.push(item.url);
          }
        });
        // 历届资讯
        fairNews = list.filter((i: any) => i.type == 1); // type = 1 资讯, 4图片

        // 历届现场图片
        fairImage = imageList.slice(0, 6);
      }
      industryId = [fair.industry1, fair.industry2, fair.industry3].filter(
        (id) => !!id
      );
      industryName = fair.industry?.split(',') || [];
      industryName?.forEach((name, index) => {
        industry.push({
          id: industryId[index],
          name,
        });
      });
      // console.log('1404-展会详情', fair);
      // online 1:上架 2:未上架
      if ((fair.exhibitionAlias ?? '') !== '') {
        // 获取历届列表
        await ctx.$axios
          .post('/api/wiki/history/list', {
            locale: langApi,
            exhibitionAlias: fair.exhibitionAlias,
            exhibitionId: fair.exhibitionId,
            startSessionNum: fair.numSessions,
            pageNum: 1,
            pageSize: 10,
          })
          .then((historyRes: any) => {
            // console.log('577-', historyRes);
            if (historyRes.code === ResponseCode.SUCCESS) {
              let list: any = historyRes.data.records;
              list.map((item: any) => {
                let numSessions = item.numSessions
                  ? '第' + item.numSessions + '届'
                  : '';
                item['name'] = `${numSessions}${item.exhibitionName}`;
              });
              historyFairList = list;
            } else {
              apiErrorList.push({
                code: historyRes.code,
                message: historyRes.message,
              });
            }
          });
      }
      if (
        (!_.isNil(fair.online) && fair.online !== 1 && id !== '4') ||
        _.isNil(fairRes.data)
      ) {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    } else {
      apiErrorList.push({ code: fairRes.code, message: fairRes.message });
    }

    // 内链
    let innerChainActive: number = 0;
    let paramsIndustry: any[] = [...industryId];
    // if (secondIndustryId) paramsIndustry.push(secondIndustryId);
    // if (firstIndustryId) paramsIndustry.push(firstIndustryId);
    const innerChainAllRes: any = await Promise.all([
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门地区',
        pageSize: 16,
        // locale: detail.locale,
      }),
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门行业',
        industryIdList: paramsIndustry,
        pageSize: 16,
        // locale: detail.locale,
      }),
      ctx.$axios.post('/api/innerChain/page', {
        category: '热门展会',
        industryIdList: paramsIndustry,
        pageSize: 16,
        // locale: detail.locale,
      }),
    ]);

    const [innerChainAreaRes, innerChainIndustryRes, innerChainExhRes] =
      innerChainAllRes;
    let innerChainAreaList: any[] = [];
    let innerChainIndustryList: any[] = [];
    let innerChainExhList: any[] = [];

    if (innerChainAreaRes.code === ResponseCode.SUCCESS) {
      innerChainAreaList = innerChainAreaRes.data.records;
    } else {
      apiErrorList.push({
        code: innerChainAreaRes.code,
        message: innerChainAreaRes.message,
      });
    }
    if (innerChainIndustryRes.code === ResponseCode.SUCCESS) {
      innerChainIndustryList = innerChainIndustryRes.data.records;
      let len = innerChainIndustryList.length;
      if (len < 16) {
        let ctxIndustry: any = await Promise.all([
          ctx.$axios.post('/api/innerChain/page', {
            category: '热门行业',
            pageSize: 16 - len,
          }),
        ]);

        const [innerChainArea2Res] = ctxIndustry;
        if (innerChainArea2Res.code === ResponseCode.SUCCESS) {
          innerChainIndustryList = innerChainIndustryList.concat(
            innerChainArea2Res.data.records
          );
        } else {
          apiErrorList.push({
            code: innerChainArea2Res.code,
            message: innerChainArea2Res.message,
          });
        }
      }
    } else {
      apiErrorList.push({
        code: innerChainIndustryRes.code,
        message: innerChainIndustryRes.message,
      });
    }
    if (innerChainExhRes.code === ResponseCode.SUCCESS) {
      innerChainExhList = innerChainExhRes.data.records;
      let len = innerChainExhList.length;
      if (len < 16) {
        let ctxIndustry: any = await Promise.all([
          ctx.$axios.post('/api/innerChain/page', {
            category: '热门展会',
            pageSize: 16 - len,
          }),
        ]);

        const [innerChainExh2Res] = ctxIndustry;
        if (innerChainExh2Res.code === ResponseCode.SUCCESS) {
          innerChainExhList = innerChainExhList.concat(
            innerChainExh2Res.data.records
          );
        } else {
          apiErrorList.push({
            code: innerChainExh2Res.code,
            message: innerChainExh2Res.message,
          });
        }
      }
    } else {
      apiErrorList.push({
        code: innerChainExhRes.code,
        message: innerChainExhRes.message,
      });
    }

    return {
      lang,
      updateTime: getTodayDate(),
      fairId: id,
      historyId,
      exhibitionAliasId,
      fair,
      fairImage,
      historyFairList,
      hotWikiList,
      attribution: `&copy; <span>${
        _.get(fair, 'pavilionInfo.pavilionName', null) ||
        _.get(fair, 'pavilionInfo.pavilionEnglishName', null)
      }</span>`,
      center: [
        _.get(fair, 'pavilionInfo.latitude', 0),
        _.get(fair, 'pavilionInfo.longitude', 0),
      ],
      // industry,
      // industryId,
      // industryName,
      fairNews,
      innerChainActive,
      innerChainAreaList,
      innerChainIndustryList,
      innerChainExhList,
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  data(): any {
    return {
      isLoading: false,
      timeSliceTypeOptions: {
        0: '全天',
        1: '上午',
        2: '下午',
        3: '夜间',
      },
      hesuan,
      visitors: {},
      fairId: '',
      isLogin: !!this.$cookies.get('access_token'),
      num: 1,
      code: {},
      logo: require('@/assets/icon/ali.png'),
      fair: {},
    };
  },
  // isLogin
  watch: {
    login(val) {
      if (val) {
      }
    },
  },
  computed: {
    ...mapState({
      login: (state: any) => state.global.isLogin,
    }),
    pavilionPicture(): string[] {
      const val = _.get(this.fair, 'pavilionInfo.picture', '');
      try {
        return val.split(';');
      } catch (e) {
        return [val];
      }
    },
  },
  created() {
    if (process.client) {
    }
    // const token = this.$cookies.get('access_token');
    // if (token) {

    // }
  },
  mounted() {},
  methods: {
    xss,
    getXOssProcess,
    getContent,
    moment,
    handleNewsUrl,
    goToInformation() {
      this.$windowOpen(`/expoinfo/${this.$route.params.id}.html`, '_blank');
    },
    handleHotTab(e: any) {
      this.innerChainActive = e;
    },

    back() {
      this.activeStep = 'ticket';
    },
    handleDetail(id: number) {
      window.open(`/ticket/${id}.html`);
    },

    // this.fairId

    toLogin() {
      this.$login.show();
      // const { fullPath } = this.$route;
      // this.$router.push({
      //   path: `/login?redirect=${encodeURIComponent(fullPath)}`,
      // });
    },
  },
  head(): Record<string, any> {
    const {
      startTime = '',
      endTime = '',
      exhibitionAlias = '',
      exhibitionName = '',
      introduction = '',
      industry = '',
      organizer = '',
      // pic,
      pavilionInfo = {},
    } = this.$data.fair;
    const {
      areaName = '',
      regionName = '',
      pavilionName = '',
    } = (pavilionInfo || {}) as Record<string, any>;
    const industrys = industry?.split(',');
    const industryName = _.get(industrys, '[0]', '');
    let description = introduction
      ? introduction.replace(/<[^>]*>/g, '').slice(0, 100)
      : '';
    return {
      title: `${exhibitionAlias} - 展会百科 - 聚展`,
      meta: [
        {
          name: 'keywords',
          content: `${exhibitionAlias},${exhibitionName}`,
        },
        {
          name: 'description',
          content: `${description}`,
        },
      ],
    };
  },
});
